import {Component} from 'react';



export class GlobalVariable extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {

        };


    }
    static staffBack() {
        return 'https://apistaff.chio-chio.ru';
        // return 'https://devchiochio.ru';
    }


    static host() {
        return 'https://beta.chio-chio.ru';
        // return 'https://devchiochio.ru';
    }

}
export default GlobalVariable;