import React, {Component} from 'react';
import PageHeader from "../PageHeader";
import Menu from "../Menu";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import {Link} from "react-router-dom";

const cards = [{
    id: 0,
    title: 'Электронный сертификат ОЗОН',
    description: 'Номиналом 500 руб',
    cost: 1000,
    url: '/img/benefit/ozon.svg',
    category: 'Медный',
    status: true
}, {
    id: 1,
    title: 'Электронный сертификат ОЗОН',
    description: 'Номиналом 700 руб',
    cost: 1400,
    url: '/img/benefit/ozon.svg',
    category: 'Бронзовый',
    status: true
}, {
    id: 2,
    title: 'Сертификат на подписку Яндекс+',
    description: '3 месяца',
    cost: 2380,
    url: '/img/benefit/yandex.png',
    category: 'Серебряный',
    status: true
}, {
    id: 3,
    title: 'Набор косметики Чио Чио для ежедневного применения большой',
    description: 'Шампунь для ежедневного применения 1000мл Бальзам для ежедневного применения 1000мл Маска для ежедневного применения для всех типов волос 150мл',
    cost: 2000,
    url: '/img/benefit/bottle.png',
    category: 'Золотой',
    status: true
}, {
    id: 4,
    title: 'Набор косметики Чио Чио для ежедневного применения большой',
    description: 'Шампунь для ежедневного применения 1000мл Бальзам для ежедневного применения 1000мл Маска для ежедневного применения для всех типов волос 150мл',
    cost: 1500,
    url: '/img/benefit/bottle.png',
    category: 'Платиновый',
    status: true
}]


export class BenefitCafe extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoad: false,
            benefits: []
        };
    }

    componentDidMount() {
        let urlAPI2 = GlobalVariable.staffBack() + '/getBenefitList'; //TODO api для получения списка бенефитов
        const data = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
        })
            .then(result => { // then print response status
                const sortedBenefits = result.data.sort((a, b) => a.cost > b.cost ? 1 : -1);
                this.setState({
                    benefits: sortedBenefits, // TODO формат как выше в cards
                    isLoad: true
                })
            })
            .catch(error => { // TODO удалить когда подгрузится с бэка
                const sortedBenefits = cards.sort((a, b) => a.cost > b.cost ? 1 : -1);
                this.setState({
                    benefits: sortedBenefits,
                    isLoad: true
                })
            })
    }

    renderContent() {
        return (
            <div>
                <div className='pl-3 pr-3'>

                    <h2>Уровень: уточняем Ваш уровень</h2>
                    <h2>Чиокоинов: 0</h2>
                    <br/>

                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between '>
                        <span className='text-white txt-middle'>Медный уровень</span>
                    </div>

                    {this.state.benefits.filter(b => (b.category === 'Медный' && (b.status === true))).map((b) => (
                        <div key={b.title + b.description} className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex'
                             style={{minHeight: '130px'}}>
                            <div className='w-40'>
                                <img src={b.url}
                                     style={{
                                         position: 'absolute',
                                         bottom: '0',
                                         left: '0',
                                         borderRadius: '0 0 0 20px',
                                         maxWidth: '40%',
                                         maxHeight: '100%'
                                     }}/>
                            </div>
                            <div className='w-60 d-flex flex-column'>
                                <div>
                                    <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                        {b.title}
                                    </div>
                                    <div className='mb-1' style={{fontSize: '10px', lineHeight: '1.05'}}>
                                        {b.description}
                                    </div>
                                </div>
                                <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                    <div>
                                        <img src='/img/icons/chiocoins.svg' height='15px'
                                             style={{marginTop: '-3px'}}/> {b.cost}
                                    </div>
                                    {/*<Link to={'/benefit-cafe-get/' + b.id} style={{*/}
                                    {/*    backgroundColor: 'var(--red-chio-color)',*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: ' 5px 10px',*/}
                                    {/*    marginLeft: 'auto',*/}
                                    {/*    borderRadius: '5px'*/}
                                    {/*}}>*/}
                                    {/*    Получить*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between mt-3'>
                        <span className='text-white txt-middle'>Бронзовый уровень</span>
                    </div>
                    {this.state.benefits.filter(b => b.category === 'Бронзовый' && (b.status === true)).map((b) => (
                        <div key={b.title + b.description} className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex'
                             style={{minHeight: '130px'}}>
                            <div className='w-40'>
                                <img src={b.url}
                                     style={{
                                         position: 'absolute',
                                         bottom: '0',
                                         left: '0',
                                         borderRadius: '0 0 0 20px',
                                         maxWidth: '40%',
                                         maxHeight: '100%'
                                     }}/>
                            </div>
                            <div className='w-60 d-flex flex-column'>
                                <div>
                                    <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                        {b.title}
                                    </div>
                                    <div className='mb-1' style={{fontSize: '10px', lineHeight: '1.05'}}>
                                        {b.description}
                                    </div>
                                </div>
                                <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                    <div>
                                        <img src='/img/icons/chiocoins.svg' height='15px'
                                             style={{marginTop: '-3px'}}/> {b.cost}
                                    </div>
                                    {/*<Link to={'/benefit-cafe-get/' + b.id}  style={{*/}
                                    {/*    backgroundColor: 'var(--red-chio-color)',*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: ' 5px 10px',*/}
                                    {/*    marginLeft: 'auto',*/}
                                    {/*    borderRadius: '5px'*/}
                                    {/*}}> Получить*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    ))}


                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between mt-3'>
                        <span className='text-white txt-middle'>Серебряный уровень</span>
                    </div>
                    {this.state.benefits.filter(b => b.category === 'Серебряный' && (b.status === true)).map((b) => (
                        <div key={b.title + b.description} className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex'
                             style={{minHeight: '130px'}}>
                            <div className='w-40'>
                                <img src={b.url}
                                     style={{
                                         position: 'absolute',
                                         bottom: '0',
                                         left: '0',
                                         borderRadius: '0 0 0 20px',
                                         maxWidth: '40%',
                                         maxHeight: '100%'
                                     }}/>
                            </div>
                            <div className='w-60 d-flex flex-column'>
                                <div>
                                    <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                        {b.title}
                                    </div>
                                    <div className='mb-1' style={{fontSize: '10px', lineHeight: '1.05'}}>
                                        {b.description}
                                    </div>
                                </div>
                                <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                    <div>
                                        <img src='/img/icons/chiocoins.svg' height='15px'
                                             style={{marginTop: '-3px'}}/> {b.cost}
                                    </div>
                                    {/*<div style={{*/}
                                    {/*    backgroundColor: 'var(--red-chio-color)',*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: ' 5px 10px',*/}
                                    {/*    marginLeft: 'auto',*/}
                                    {/*    borderRadius: '5px'*/}
                                    {/*}}> Получить*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    ))}


                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between mt-3'>
                        <span className='text-white txt-middle'>Золотой уровень</span>
                    </div>
                    {this.state.benefits.filter(b => b.category === 'Золотой' && (b.status === true)).map((b) => (
                        <div key={b.title + b.description} className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex'
                             style={{minHeight: '130px'}}>
                            <div className='w-40'>
                                <img src={b.url}
                                     style={{
                                         position: 'absolute',
                                         bottom: '0',
                                         left: '0',
                                         borderRadius: '0 0 0 20px',
                                         maxWidth: '40%',
                                         maxHeight: '100%'
                                     }}/>
                            </div>
                            <div className='w-60 d-flex flex-column'>
                                <div>
                                    <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                        {b.title}
                                    </div>
                                    <div className='mb-1' style={{fontSize: '10px', lineHeight: '1.05'}}>
                                        {b.description}
                                    </div>
                                </div>
                                <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                    <div>
                                        <img src='/img/icons/chiocoins.svg' height='15px'
                                             style={{marginTop: '-3px'}}/> {b.cost}
                                    </div>
                                    {/*<div style={{*/}
                                    {/*    backgroundColor: 'var(--red-chio-color)',*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: ' 5px 10px',*/}
                                    {/*    marginLeft: 'auto',*/}
                                    {/*    borderRadius: '5px'*/}
                                    {/*}}> Получить*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    ))}


                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between mt-3'>
                        <span className='text-white txt-middle'>Платиновый уровень</span>
                    </div>
                    {this.state.benefits.filter(b => b.category === 'Платиновый' && (b.status === true)).map((b) => (
                        <div key={b.title + b.description} className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex'
                             style={{minHeight: '130px'}}>
                            <div className='w-40'>
                                <img src={b.url}
                                     style={{
                                         position: 'absolute',
                                         bottom: '0',
                                         left: '0',
                                         borderRadius: '0 0 0 20px',
                                         maxWidth: '40%',
                                         maxHeight: '100%'
                                     }}/>
                            </div>
                            <div className='w-60 d-flex flex-column'>
                                <div>
                                    <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                        {b.title}
                                    </div>
                                    <div className='mb-1' style={{fontSize: '10px', lineHeight: '1.05'}}>
                                        {b.description}
                                    </div>
                                </div>
                                <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                    <div>
                                        <img src='/img/icons/chiocoins.svg' height='15px'
                                             style={{marginTop: '-3px'}}/> {b.cost}
                                    </div>
                                    {/*<div style={{*/}
                                    {/*    backgroundColor: 'var(--red-chio-color)',*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: ' 5px 10px',*/}
                                    {/*    marginLeft: 'auto',*/}
                                    {/*    borderRadius: '5px'*/}
                                    {/*}}> Получить*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        )
    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Бенефит кафе'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}
                </div>
                <Menu/>
            </div>
        )
    }
}

export default BenefitCafe;