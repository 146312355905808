import React, {Component} from "react";
import PageHeader from "../PageHeader";
import Menu from "../Menu";
import {Link} from "react-router-dom";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";

export class Tips extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            company:[],
            tipsAll:true
        };
    }
    staffSendTips() {

        let urlAPI = GlobalVariable.host() + '/mobile/cloud-payments/requestStaff';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.staffInfoTips()
            }).catch(error => {
            this.setState({

            })

        })
    }
    staffInfoTips() {

        let urlAPI = GlobalVariable.host() + '/mobile/cloud-payments/status';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    company:result.data.company,
                    tipsAll:result.data.tips
                })
            }).catch(error => {
            this.setState({

            })

        })
    }
    componentDidMount() {
        this.staffInfoTips()
    }

    render() {
        return (
            <div className='main news tips'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Чаевые'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    <div className='ml-3 mr-3'>
                        {/*<h2 className='txt-xlarge bold'>Сумма чаевых: 100р</h2>*/}
                        <br/>
                        <h2 className='txt-large bold'>Чаевые</h2>
                        <p className='font-weight-normal'>
                           Мы используем сервис чаевых CloudTips: Электронные чаевые картой онлайн.
                            Вы сможете получить чаевые, через терминал или по qr коду размещенных в парикмахерской
                        </p>
                        {!this.state.tipsAll?<div className="mt-2 mb-3 d-flex">
                            <div  className='w-100 text-center bg-darkch text-white chio-card p-3 txt-middle'>
                                Заявка на&nbsp;подключение&nbsp;чаевых успешно&nbsp;отправлена
                            </div>
                        </div>:<div className="mt-2 mb-3 d-flex">
                            <div onClick={()=>this.staffSendTips()} className='w-100 text-center bg-darkch text-white chio-card p-3 txt-middle'>
                                Отправить заявку на&nbsp;подключение&nbsp;чаевых
                            </div>
                        </div>}

                        {
                            this.state.company.map((item)=>(
                                <p>{item}</p>
                            ))
                        }


                        <img src='/img/tips.png' alt='Картинка'/>
                    </div>
                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Tips;