import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";
import PageHeader from "../PageHeader";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";

export class Event extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
        };
    }


    componentDidMount() {
        let urlAPI2 = GlobalVariable.staffBack() + '/getEvent/' + this.props.idEvent;
        const data2 = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data2,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    events: result.data,
                })
                console.log(result.data)
            }).catch(error => {


            // window.location.href = '/' ;
        })
    }

    renderContent() {
        if (this.state.events) {
            return (
                <div className='pl-3 pr-3 '>
                    <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between '>
                        <span className='text-white txt-middle'></span>
                        <span className='text-white txt-middle'>
                                <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                                10.09.20224 18:00 по МСК
                            </span>
                    </div>
                    <div className='chio-card mb-2 p-3 mb-3'>
                        <div className='txt-large'>{this.state.events.title}</div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: this.state.events.content}}>


                    </div>


                </div>
            )
        }

    }


    render() {
        return (
            <div className='main event'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Событие'
                        backBtnUrl='/events'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Event;