import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Global from "./components/Global";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Start from "./components/Start/Start";
import Record from "./components/Record/Record";

import Country from "./components/ListCompany/Country";
import City from "./components/ListCompany/City";
import Point from "./components/ListCompany/Point";
import Date from "./components/Record/Date";
import Service from "./components/Record/Service";
import Staff from "./components/Record/Staff";
import MyRecord from "./components/Record/MyRecord";


import Analytics from "./components/Analytics/Analytics";
import DateSchedule from "./components/Record/DateSchedule";
import Event from "./components/Events/Event";
import Events from "./components/Events/Events";
import KnowledgeBase from "./components/KnowledgeBase/KnowledgeBase";
import KnowledgeCategories from "./components/KnowledgeBase/KnowledgeCategories";
import Notifications from "./components/Notifications/Notifications";
import ProfileChange from "./components/Profile/ProfileChange";
import Lesson from "./components/KnowledgeBase/Lesson";
import Support from "./components/Support/Support";
import ScheduleStaffs from "./components/Schedule/ScheduleStaffs";
import Index from "./components/Index";
import WantToWork from "./components/Login/WantToWork";
import MasterDefine from "./components/Login/MasterDefine";
import Lesson1 from "./components/KnowledgeBase/Lesson/Lesson1";
import Lesson2 from "./components/KnowledgeBase/Lesson/Lesson2";
import Lesson3 from "./components/KnowledgeBase/Lesson/Lesson3";
import Lesson4 from "./components/KnowledgeBase/Lesson/Lesson4";
import Lesson5 from "./components/KnowledgeBase/Lesson/Lesson5";
import Lesson6 from "./components/KnowledgeBase/Lesson/Lesson6";
import Lesson7 from "./components/KnowledgeBase/Lesson/Lesson7";
import Lesson8 from "./components/KnowledgeBase/Lesson/Lesson8";
import Lesson9 from "./components/KnowledgeBase/Lesson/Lesson9";
import Lesson10 from "./components/KnowledgeBase/Lesson/Lesson10";
import Lesson11 from "./components/KnowledgeBase/Lesson/Lesson11";
import Lesson12 from "./components/KnowledgeBase/Lesson/Lesson12";
import KnowledgeBase1 from "./components/KnowledgeBase/Category/KnowledgeBase1";
import KnowledgeBase2 from "./components/KnowledgeBase/Category/KnowledgeBase2";
import KnowledgeBase3 from "./components/KnowledgeBase/Category/KnowledgeBase3";
import Event1 from "./components/Events/Event/Event1";
import Event2 from "./components/Events/Event/Event2";
import Event3 from "./components/Events/Event/Event3";
import Chat from "./components/Chat/Chat";
import Event4 from "./components/Events/Event/Event4";
import { withRouter } from "react-router"
import ChiocoinsHistory from "./components/ChiocoinsHistory/ChiocoinsHistory";
import BenefitCafe from "./components/ChiocoinsBenefitCafe/BenefitCafe";
import CreateRecord from "./components/Record/CreateRecord";
import Tips from "./components/Tips/Tips";
import BenefitCafeGet from "./components/ChiocoinsBenefitCafe/BenefitCafeGet";

ReactDOM.render(

    <Router>
        <Switch>
            {/*Вход, авторизация, регистрация*/}
            <Route path="/login" children={<LoginFunc/>}/>
            <Route path="/start" children={<Start/>}/>
            <Route path="/want-to-work" children={<WantToWork/>}/>
            <Route path="/master-define" children={<MasterDefine/>}/>

            <Route path="/analytics" children={<Analytics/>}/>
            <Route path="/chat" children={<Chat/>}/>
            <Route path="/date-schedule" children={<DateSchedule/>}/>

            <Route path="/date-schedule-staffs" children={<ScheduleStaffs/>}/>

            <Route path="/event/:idEvent" children={<EventFunc/>}/>
            <Route path="/events" children={<Events/>}/>
            <Route path="/knowledge-base/category/lesson" children={<Lesson/>}/>
            <Route path="/knowledge-base/category/lesson1" children={<Lesson1/>}/>
            <Route path="/knowledge-base/category/lesson2" children={<Lesson2/>}/>
            <Route path="/knowledge-base/category/lesson3" children={<Lesson3/>}/>
            <Route path="/knowledge-base/category/lesson4" children={<Lesson4/>}/>
            <Route path="/knowledge-base/category/lesson5" children={<Lesson5/>}/>
            <Route path="/knowledge-base/category/lesson6" children={<Lesson6/>}/>
            <Route path="/knowledge-base/category/lesson7" children={<Lesson7/>}/>
            <Route path="/knowledge-base/category/lesson8" children={<Lesson8/>}/>
            <Route path="/knowledge-base/category/lesson9" children={<Lesson9/>}/>
            <Route path="/knowledge-base/category/lesson10" children={<Lesson10/>}/>
            <Route path="/knowledge-base/category/lesson11" children={<Lesson11/>}/>
            <Route path="/knowledge-base/category/lesson12" children={<Lesson12/>}/>
            <Route path="/knowledge-base/category/lesson13" children={<Lesson12/>}/>
            <Route path="/knowledge-base/category1" children={<KnowledgeBase1/>}/>
            <Route path="/knowledge-base/category2" children={<KnowledgeBase2/>}/>
            <Route path="/knowledge-base/category3" children={<KnowledgeBase3/>}/>
            <Route path="/chiocoins-history" children={<ChiocoinsHistory/>}/>
            <Route path="/benefit-cafe" children={<BenefitCafe/>}/>
            <Route path="/benefit-cafe-get/:idBenefit" children={<BenefitFunc/>}/>
            <Route path="/create-record" children={<CreateRecord/>}/>
            <Route path="/tips" children={<Tips/>}/>

            <Route path="/knowledge-base" children={<KnowledgeCategories/>}/>
            <Route path="/notifications" children={<Notifications/>}/>
            <Route path="/profile" children={<ProfileChange/>}/>
            <Route path="/records" children={<MyRecord/>}/>
            <Route path="/support" children={<Support/>}/>
            <Route path="/schedule" children={<ScheduleStaffs/>}/>
            <Route path="/exit" children={<Home/>}/>
            <Route path="/" children={<Home/>}/>


            {/*<Route path="/record" children={<Record/>}/>*/}
            {/*/!*<Route path="/statistics" children={<MyStatistics/>}/>*!/*/}
            {/*<Route path="/country" children={<Country/>}/>*/}
            {/*<Route path="/city" children={<City/>}/>*/}
            {/*<Route path="/point/:idPoint" children={<PointFunc/>}/>*/}
            {/*<Route path="/point" children={<Point/>}/>*/}
            {/*<Route path="/date" children={<Date/>}/>*/}
            {/*<Route path="/date" children={<Date/>}/>*/}
            {/*<Route path="/service" children={<Service/>}/>*/}
            {/*<Route path="/staff" children={<Staff/>}/>*/}
            {/*<Route path="/home" children={<HomeFunc/>}/>*/}

            {/*<Route path="/chat" children={<Chat/>}/>*/}
            {/*<Route path="/profile-create" children={<ProfileCreate/>}/>*/}

        </Switch>
    </Router>,
    document.getElementById('root')
);

function HomeFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {widgetId, idCity} = useParams();
    return <Home/>;
}

function LoginFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {widgetId, idCity} = useParams();
    return <Login/>;
}

function EventFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {idEvent} = useParams();
    return <Event idEvent={idEvent}/>;
}

function BenefitFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {idBenefit} = useParams();
    return <BenefitCafeGet idBenefit={idBenefit}/>;
}

function PointFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {idPoint} = useParams();
    return <Point idPoint={idPoint}/>;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
