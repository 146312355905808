import React, {Component} from 'react';
import PageHeader from "../PageHeader";
import {Simulate} from "react-dom/test-utils";

export class CreateRecord extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoad: true,
            isWhatsAppUse: true
        };
    }

    render() {
        return (
            <div className='main schedule'>
                <div className='content mx-w-content  position-relative'>
                    <PageHeader
                        pageTitle='Записать клиента'
                        backBtnUrl='/'/>

                    <div className='pt-header'></div>

                    <div className='ml-3 mr-3'>
                        <form>
                            <label>Номер телефона клиента</label><br/>
                            <input type="number" id="inputPassword5" className="form-control"
                                   aria-describedby="passwordHelpBlock"/>


                            <label className='mt-3'>Адрес</label>
                            <select className="form-select form-select-sm mt-0" aria-label="Small select example mb-3">
                                <option value="0" selected>Выбрать адрес</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>

                            <label className='mt-3'>Дата</label>
                            <input className='form-select' type='date'/>

                            <label className='mt-3'>Время</label>
                            <input className='form-select' type='time'/>

                            <label className='mt-3'>Мастер</label>
                            <select className="form-select form-select-sm mt-0" aria-label="Small select example mb-3">

                                <option value="0" selected>Выбрать мастера</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>

                            <label className='mt-3'>Услуги</label>
                            <select className="form-select mt-1" size={7} multiple aria-label="Multiple select example">
                                <optgroup label="Категория 1">
                                    <option selected>Одна/несколько услуг</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    <option value="4">One</option>
                                </optgroup>
                                <optgroup label="Категория 2">
                                    <option value="5">Two</option>
                                    <option value="6">Three</option>
                                    <option value="7">One</option>
                                    <option value="8">Two</option>
                                    <option value="9">Three</option>
                                </optgroup>
                            </select>

                            <div className="form-floating mt-3">
                                <textarea className="form-control" placeholder="Leave a comment here"
                                          id="floatingTextarea2" style={{height: '100px'}}></textarea>
                                <label htmlFor="floatingTextarea2">Комментарий</label>
                            </div>

                            <div className="form-check mt-3">
                                <input className="form-check-input" type="checkbox" checked
                                       disabled={!(this.state.isWhatsAppUse)}
                                       id="flexCheckIndeterminate"/>
                                <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                                    Уведомить клиента по&nbsp;WhatsApp
                                </label>
                                <div className='txt-small red-text'>
                                    {!(this.state.isWhatsAppUse) && 'Функция уведомления клиента недоступна т.к. не подключен сервис WhatsApp рассылки'}
                                </div>
                            </div>

                            <button type="button" className="btn btn-primary btn-lg m-auto mt-3">Записать</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateRecord;