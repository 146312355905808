import React, {Component} from 'react';

export class Menu extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log(window.location.search)

        return (<div className={'preloader'}>
            <div className="loader">loading</div>
                {/*<Menu></Menu>*/}
            </div>
        )
    }
}

export default Menu;