import React, {Component} from 'react';
import PageHeader from "../PageHeader";
import Menu from "../Menu";


export class ChiocoinsHistory extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    renderContent() {
        return (
            <div>
                <div className='pl-3 pr-3 '>
                    <div className='mb-3 pl-3 pr-3 txt-large bold text-center'>
                        Баланс 5000 <img src='/img/icons/chiocoins.svg' height='22px' style={{marginTop: '-5px'}} alt=''/>
                    </div>

                    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>
                        <div className='txt-middle d-flex justify-content-end'>13.09.2024 11:13</div>
                        <div className='txt-middle bold'>
                            -6000 <img src='/img/icons/chiocoins.svg' height='15px' style={{marginTop: '-3px'}} alt=''/>
                        </div>
                        <div className='txt-middle'>Списание за толстовку</div>
                    </div>

                    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>
                        <div className='txt-middle d-flex justify-content-end'>13.09.2024 11:13</div>
                        <div className='txt-middle bold'>
                            +400 <img src='/img/icons/chiocoins.svg' height='15px' style={{marginTop: '-3px'}} alt=''/>
                        </div>
                        <div className='txt-middle'>Пополнение за проведенный мастеркласс</div>
                    </div>

                    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>
                        <div className='txt-middle d-flex justify-content-end'>13.09.2024 11:13</div>
                        <div className='txt-middle bold'>
                            +150 <img src='/img/icons/chiocoins.svg' height='15px' style={{marginTop: '-3px'}} alt=''/>
                        </div>
                        <div className='txt-middle'> Пополнение за сторис</div>
                    </div>
                </div>

            </div>
        )
    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Чиокоины история'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}
                </div>
                <Menu/>
            </div>
        )
    }
}

export default ChiocoinsHistory;