import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";
import PageHeader from "../PageHeader"
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";

export class Events extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            events:[],
        };
    }

    componentDidMount() {
        let urlAPI2 = GlobalVariable.staffBack() + '/getEvents';
        const data2 = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data2,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    events: result.data,
                })
            }).catch(error => {

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }


    render() {
        return (
            <div className='main news events '>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='События'
                        backBtnUrl='/'/>
                        <div className='pt-header'></div>

                    <div className='ml-3 mr-3'>

                        {
                            this.state.events.map((item) => (
                                <Link to={'/event/'+item.id
                                }>
                                    <div
                                        className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                        <span className='text-white txt-middle'>{item.date}</span>
                                        <span className='text-white txt-middle'></span>
                                    </div>
                                    <div className='chio-card mb-2'>
                                        <img className='card-img-wh' alt=''
                                             src={item.img_url}/>
                                        <div className='p-3 txt-middle bold'>
                                            {item.title}
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }

                    </div>
                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Events;