import React, {Component} from 'react';
import Menu from "../Menu";
import PageHeader from "../PageHeader";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import {number} from "@amcharts/amcharts4/core";


export class ScheduleStaffs extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showBlock: false,
            showBlockMonth: false,
            activeAddress: 'Тестовая точка 1',
            activeMonth: 'Загружаем месяц',
            activeAddressId: null,
            activeMonthApi: null,
            dayMonth: [],
            activeDay: [],
            staffSchedule: [],
            monthArrayName: [],
            timePeriod: [{id: 1, from: '10:00', to: '20:00', onlineRecord: true}],
            AddressArray: [],
            isOpenChooseTime: false,
            SettingScheduleYourself: false,
            SettingScheduleAll: false,
        };
        this.changeActiveMonth = this.changeActiveMonth.bind(this);
    }

    getInfo(activeMonthApi = null, activeAddressId = null) {
        let urlAPI = GlobalVariable.host() + '/widget/staff/date-schedule/' + activeMonthApi + '/' + activeAddressId;
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    staffSchedule: result.data.staffSchedule,
                    dayMonth: result.data.dayMonth,
                    AddressArray: result.data.CompanyUserEnable,
                    activeAddress: result.data.CompanyUserEnable[0].address,
                    monthArrayName: result.data.monthArrayName,
                    activeMonthApi: result.data.activeMonth.apiDate,
                    activeMonth: result.data.activeMonth.alias,
                    SettingScheduleAll: result.data.SettingScheduleAll,
                    SettingScheduleYourself: result.data.SettingScheduleYourself,

                    isLoad: true,
                    auth: true
                })
                console.log(result.data)
            }).catch(error => {
            if (error.code == 401) {
                window.location.href = '/start';
            }

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }

    componentDidMount() {
        this.getInfo()
    }

    changeActiveAddress(address, id) {
        this.setState({
            'activeAddress': address,
            'activeAddressId': id,
            'showBlock': false,
        })
        this.getInfo(this.state.activeMonthApi, id)
    }

    changeActiveMonth(month) {
        this.setState({
            'activeMonth': month.alias,
            'activeMonthApi': month.apiDate,
            'showBlockMonth': false,
        })
        console.log(month)
        this.getInfo(month.apiDate, this.state.activeAddressId)
    }

    showBlock() {
        if (this.state.showBlock) {
            return (
                <div style={{width: '80%', margin: '0 auto'}}>
                    {
                        this.state.AddressArray.map((itemMonth) => (
                            <div className='mt-2'
                                 onClick={() => this.changeActiveAddress(itemMonth.address, itemMonth.id)}
                                 style={{textAlign: 'center', textDecoration: 'underline'}}>{itemMonth.address}
                            </div>
                        ))
                    }

                </div>
            )
        }
    }

    showBlockMonth() {
        if (this.state.showBlockMonth) {

            return (
                <div style={{width: '80%', margin: '0 auto'}}>
                    {

                        this.state.monthArrayName.map((itemMonth) => (
                            <div className='mt-2' onClick={() => this.changeActiveMonth(itemMonth)}
                                 style={{textAlign: 'center', textDecoration: 'underline'}}>{itemMonth.alias}
                            </div>
                        ))
                    }

                </div>
            )
        }
    }

    searchDayActive(newArray, array, arr = true) {
        let status = false;
        let index = null;
        newArray.forEach(function (item, i, newArray) {

            if (JSON.stringify(item) == JSON.stringify(array)) {
                status = true;
                index = i;
                return {status: status, index: index}
            }
        });

        return {status: status, index: index}
    }

    activeDay(array) {
        if (this.state.SettingScheduleAll) {


            let newArray = this.state.activeDay;

            console.log(newArray)
            let status = this.searchDayActive(newArray, array, true)

            if (!status?.status) {
                newArray.push(array)
                this.setState({
                    activeDay: newArray
                })
            } else {
                if (status.index != null) {
                    // @ts-ignore
                    delete newArray[status.index];

                    this.setState({
                        activeDay: newArray
                    })
                }
            }
        }
    }

    changeTimePeriodOnlineRecord(id, onlineRecord) {
        console.log([id, onlineRecord])

        let newArr = this.state.timePeriod;
        let status = !this.state.timePeriod[id].onlineRecord
        let newArr2 = {
            id: id,
            from: this.state.timePeriod[id].from,
            to: this.state.timePeriod[id].to,
            onlineRecord: status
        }
        newArr.splice(id, 1, newArr2);
        console.log(newArr)
        this.setState({
            timePeriod: newArr
        })
    }

    changeTimePeriodOnlineTo(id, to) {


        let newArr = this.state.timePeriod;

        let newArr2 = {
            id: id,
            from: this.state.timePeriod[id].from,
            to: to.target.value,
            onlineRecord: this.state.timePeriod[id].onlineRecord
        }
        newArr.splice(id, 1, newArr2);
        console.log(newArr)
        this.setState({
            timePeriod: newArr
        })
    }

    changeTimePeriodOnlineFrom(id, from) {


        let newArr = this.state.timePeriod;
        let status = !this.state.timePeriod[id].onlineRecord
        let newArr2 = {
            id: id,
            from: from.target.value,
            to: this.state.timePeriod[id].to,
            onlineRecord: this.state.timePeriod[id].onlineRecord
        }
        newArr.splice(id, 1, newArr2);
        console.log(newArr)
        this.setState({
            timePeriod: newArr
        })
    }

    addSlot() {
        let newArr = this.state.timePeriod;
        newArr.push({id: 1, from: '10:00', to: '20:00', onlineRecord: true})
        this.setState({
            timePeriod: newArr
        })
    }

    deleteSlot(id) {
        let newArr = this.state.timePeriod;
        newArr.splice(id, 1);

        this.setState({
            timePeriod: newArr
        })
    }

    sendScheduleStaffs() {
        let urlAPI = GlobalVariable.host() + '/widget/staff/date-schedule-add';
        const data = {
            month: this.state.activeMonthApi,
            arrayStaffAndDate: this.state.activeDay,
            timePeriod: this.state.timePeriod,
        };
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({

                    isLoad: true,
                    auth: true
                })
                console.log(result.data)
            }).catch(error => {
            if (error.code == 401) {
                window.location.href = '/start';
            }

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }

    toggleChooseTime() {
        return (
            <div className='choose-time p-3 d-flex flex-column'>
                {/*Заголовки*/}
                <div className='d-flex justify-content-between mb-1'>
                    <div className='p-2 txt-small'>Удалить</div>
                    <div className='p-2 txt-small'>Смена с</div>
                    <div className='p-2 txt-small'>Смена до</div>
                    <div className='p-2 txt-small text-center' style={{lineHeight: '1'}}>
                        Онлайн<br/>запись
                    </div>
                </div>


                {/*Временной промежуток*/}
                {console.log(this.state.timePeriod)}
                {this.state.timePeriod.map((item, k) => (
                    <div className='d-flex justify-content-between mt-0 time-row'>
                        <div onClick={() => this.deleteSlot(k)} className='p-3 pt-4 pb-4 pl-0'><img
                            src='/img/trash3.svg'/></div>
                        <div className='p-2 pt-4 pb-4'><input onChange={(e) => this.changeTimePeriodOnlineFrom(k, e)}
                                                              value={item.from} type='time' placeholder='xx:xx'/></div>
                        <div className='p-2 pt-4 pb-4'><input onChange={(e) => this.changeTimePeriodOnlineTo(k, e)}
                                                              value={item.to} type='time' placeholder='xx:xx'/></div>
                        <div className='p-3 pt-4 pb-4 pr-0'>
                            <input onChange={() => this.changeTimePeriodOnlineRecord(k, item.onlineRecord)}
                                   type='checkbox' checked={item.onlineRecord} placeholder='xx:xx'/>
                        </div>
                    </div>
                ))}


                {/*Кнопка добавления промежутка*/}
                <div onClick={() => this.addSlot()} className='text-center mt-2 mb-4'>
                    <div className='d-inline-block plus-btn'>
                        <img src='/img/plus.svg' className='p-2 plus-btn'/>
                        Добавить промежуток
                    </div>
                </div>

                {/*Действия*/}
                <div className='d-flex justify-content-evenly align-items-center mb-0 mt-auto flex-wrap'>
                    <div className='text-center btn btn-primary' style={{width: '110px'}}
                         onClick={() => this.setState({isOpenChooseTime: false})}>Отмена
                    </div>
                    <div onClick={() => this.sendScheduleStaffs()} className='btn btn-success'
                         style={{width: '110px'}}>Сохранить
                    </div>
                    <div className='w-100 text-center mt-4 mb-2 btn btn-danger'>Пометить нерабочими
                    </div>
                </div>
            </div>
        )

    }


    render() {
        return (
            <div className='main schedule'>
                <div className='content mx-w-content  position-relative'>
                    <PageHeader
                        pageTitle='График работы'
                        backBtnUrl='/'/>

                    <div className='pt-header'></div>

                    <div
                        onClick={() => this.state.showBlock ? this.setState({'showBlock': false}) : this.setState({'showBlock': true})}
                        className='text-center txt-middle mb-4'>
                        <img className='mr-2' height='18px' src='/img/location.svg' alt=''/>
                        {this.state.activeAddress}
                        {this.showBlock()}
                    </div>
                    {/*Выбор месяца*/}
                    <div
                        onClick={() => this.state.showBlockMonth ? this.setState({'showBlockMonth': false}) : this.setState({'showBlockMonth': true})}
                        className='text-center txt-middle mb-4'>
                        <img className='mr-2' height='18px' src='/img/calendar.png' alt=''/>
                        {this.state.activeMonth}
                        {this.showBlockMonth()}
                    </div>

                    <div className='mb-2 text-center'
                         style={this.state.activeDay.length > 0 ? {height: '30px'} : {height: '30px', display: "none"}}>
                        <div className='ml-3 txt-small' style={{
                            backgroundColor: 'var(--dark-chio-color)',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '15px',
                            display: 'inline',
                        }}
                             onClick={() => this.setState({isOpenChooseTime: true})}>
                            Изменить выбранное
                        </div>
                        <div className='ml-3 txt-small' style={{
                            backgroundColor: 'var(--red-chio-color)',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '15px',
                            display: 'inline',
                        }}
                             onClick={() => this.setState({isOpenChooseTime: true})}>
                            Снять выделение
                        </div>
                    </div>

                    <div className={'outer'}>
                        <div className={'inner'}>
                            <table className="table" style={{fontSize: '12px', color: "black"}}>
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    {this.state.dayMonth.map((item) => (
                                        <td style={{textAlign: 'center'}} scope="col">{item}</td>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.staffSchedule
                                    .map((itemStaff) => (
                                        <tr>
                                            <th className='txt-small' scope="row">{itemStaff.staffName}</th>
                                            {itemStaff.day.map((v, k) => (
                                                <td className='txt-small'
                                                    onClick={() => this.activeDay([itemStaff.staffId, Number(k) + 1])}
                                                    style={this.searchDayActive(this.state.activeDay, [itemStaff.staffId, Number(k) + 1]).status ? {backgroundColor: 'greenyellow'} : {}}>
                                                    {v.map((item) => (
                                                        item.from + ' ' + item.to + ' '
                                                    ))}
                                                </td>

                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>

                    </div>
                    {/*Показываем попап с выбором интервалов работы*/}
                    {(this.state.isOpenChooseTime === true) && this.toggleChooseTime()}

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default ScheduleStaffs;