import React, {Component} from 'react';
import PageHeader from "../PageHeader";
import Menu from "../Menu";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import {Link} from "react-router-dom";

const card = {
    id: 1,
    title: 'Электронный сертификат ОЗОН',
    description: 'Номиналом 700 руб',
    cost: 1400,
    url: '/img/benefit/ozon.svg',
    category: 'Бронзовый',
    status: true
}

const req = {}

export class BenefitCafeGet extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoad: false,
            benefit: {},
            request: {} //Заявка на получение данного бенефита
        };
    }

    componentDidMount() {
        let urlAPI2 = GlobalVariable.staffBack() + '/getBenefit/' + this.props.idBenefit; //TODO api для получения конкретного бенефита
        const data = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
        })
            .then(result => { // then print response status
                this.setState({
                    benefit: result.data, // TODO формат как выше в cards
                })
            })
            .catch(error => { // TODO удалить когда подгрузится с бэка
                this.setState({
                    benefit: card,
                })
            })
    }

    renderContent() {
        return (
            <div>
                <div className='pl-3 pr-3'>

                    <h2>Ваш уровень:</h2>
                    <h2>Чиокоинов:</h2>
                    <br/>


                    <p className='pb-3'>
                        Бенефит вы можете получить, если выполнены два условия:<br/>
                        1) Ваш уровень выше или соответствует уровню бенефита (медный, бронзовый, серебряный, золотой, платиновый)<br/>
                        2) Вы накопили достаточное количество Чиокоинов
                    </p>
                    <p>

                    </p>


                    <div className="mt-2 mb-3 d-flex">
                        <button className='w-100 text-center bg-red border-0 text-white chio-card p-3 txt-middle'>
                            Отправить заявку на&nbsp;получение&nbsp;бенефита
                        </button>
                    </div>
                    <div className="mt-2 mb-3 d-flex">
                        <button className='w-100 text-center bg-red border-0 text-white chio-card p-3 txt-middle'>
                            Заявка на бенефит отправлена
                        </button>
                    </div>

                    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2 d-flex mt-4'
                         style={{minHeight: '130px'}}>
                        <div className='w-40'>
                            <img src={this.state.benefit.url}
                                 style={{
                                     position: 'absolute',
                                     bottom: '0',
                                     left: '0',
                                     borderRadius: '0 0 0 20px',
                                     maxWidth: '40%',
                                     maxHeight: '100%'
                                 }}/>
                        </div>
                        <div className='w-60 d-flex flex-column'>
                            <div>
                                <div className='txt-small bold mb-1' style={{lineHeight: '1.05'}}>
                                    {this.state.benefit.category} уровень
                                </div>
                                <div className='txt-middle bold mb-1' style={{lineHeight: '1.05'}}>
                                    {this.state.benefit.title}
                                </div>
                                <div className='mb-1' style={{fontSize: '14px', lineHeight: '1.05'}}>
                                    {this.state.benefit.description}
                                </div>
                            </div>
                            <div className='txt-middle mt-auto mt-1 bold d-flex align-items-center'>
                                <div>
                                    <img src='/img/icons/chiocoins.svg' height='15px'
                                         style={{marginTop: '-3px'}}/> {this.state.benefit.cost}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Бенефит кафе | Получить'
                        backBtnUrl='/benefit-cafe'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}
                </div>
                <Menu/>
            </div>
        )
    }
}

export default BenefitCafeGet;